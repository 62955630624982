var doCatalogFilter;

(function ($) {
    let $fn = $(".comp_products");

    if ($fn.length) {

        let filter = $fn.find(".wrp_comp_nav");

        filter.on("click","[data-click=\"expand\"]",function () {
            let el = $(this),
                list = el.next("ul");
            list.stop();
            if(el.hasClass("state--active")) {
                list.slideUp(function () {
                    el.removeClass("state--active");
                });
            }
            else {
                el.addClass("state--active");
                list.slideDown();
            }
        });

        filter.on("change","[data-toggle-category]",function () {
            let el = $(this);
            el.closest("li").find("ul").find(".part_ui_checkbox").each(function () {
                let check = $(this).find("input");
                check.prop("checked",el.prop("checked"));
            });
            doCatalogFilter(0)
        });

        filter.on("change","[data-toggle-subcategory]",function () {
            let el = $(this);
            if(el.prop("checked") === false) {
                el.closest("ul").siblings(".part_ui_checkbox").find("input").prop("checked",false);
            }
        });

        doCatalogFilter = function(page)
        {

            let filterValues = {};

            $('[data-category-check]').each(function() {
                if($(this).prop('checked') === true) {

                    let name = $(this).attr('data-name');
                    let value = $(this).val();
                    if(typeof filterValues[name] === 'undefined') {
                        filterValues[name] = [];
                    }
                    filterValues[name].push(value);
                }
            });

            //let sortType = $('select[data-sort]').val();

            let searchQuery = null;

            $.ajax({
                url: $fn.attr('data-ajax-link'),
                method: 'post',
                dataType: 'json',
                cache: false,
                data: {
                    filterData: filterValues,
                    page: (page ? page : 0),
                }
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {
                    sr.sync();
                });
            });
        };

        $fn.on('change','[data-filter-item]',function() {
            doCatalogFilter(0);
        });

        $fn.on('click','button[data-page]',function(e) {
            e.preventDefault();

            let page = $(this).attr('data-page');
            doCatalogFilter(page);
        });

    }
})(jQuery);